import dateFormat from 'dateformat'

import error from '@/services/error'

let updateCell = function(el, binding) {
  el.innerHTML = ''
  let value = binding.value

  try {
    switch (value.type) {
      case 'boolean':
        el.appendChild(document.createTextNode(value ? 'true' : 'false'))
        break
      case 'date':
        let date = value.value ? dateFormat(new Date(value.value), "yyyy-mm-dd") : 'unknown'
        el.appendChild(document.createTextNode(date))
        break
      case 'datetime':
        let datetime = value.value ? dateFormat(new Date(value.value), "yyyy-mm-dd HH:MM") : 'unknown'
        el.appendChild(document.createTextNode(datetime))
        break
      case 'html': 
        el.innerHTML = value.value
        break
      case 'link':
        const hasAnchorTag = /<a[\s\S]*?>[\s\S]*?<\/a>/i.test(value.value);
        el.innerHTML = hasAnchorTag ? value.value : `<a href="${value.value}">${value.value}</a>`;
        
        let aElems = el.getElementsByTagName('a')
        for (let elem of aElems) {
          // open link in new tab/window as default
          if (!elem.getAttribute('target')) {
            elem.setAttribute('target', '_blank')
          }
        }
        break
      default:
        el.appendChild(document.createTextNode(value.value))
    }
  } catch(e) {
    error.runtimeError(e, { silent: true, clientDebugData: {
      el: (el && el.outerHTML) ? el.outerHTML : null,
      value: value && JSON.stringify(value)
    } })
    el.appendChild(document.createTextNode(value ? value.value : ''))
  }
}

export default {
  inserted: updateCell,
  update: updateCell
}